<section class="feedback-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>Dicono di noi</h2>
            <h2><span><a href="https://www.tripadvisor.it/Restaurant_Review-g2049810-d12579136-Reviews-Borgo_Buonanotte-Santo_Stefano_Quisquina_Province_of_Agrigento_Sicily.html" target="_blank">Ci trovi anche su Tripadvisor!</a></span></h2>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback">
                <p>Siamo stati a mangiare a borgo buona notte una domenica, locale pulito e accogliente, personale alla portata di mano e cordiale... abbiamo mangiano bene e tanto, servizio ai tavoli eccellente; ti servivano un piatto accompagnato da tanto di descrizione da parte del personale di sala... consigliatissimo</p>

                <div class="client-info">
                    <h3>Linda9087</h3>
                    <span>Cliente</span>
                </div>
            </div>

            <div class="single-feedback">
                <p>Ho trascorso il pranzo della domenica in un atmosfera serena e rilassata. Il ristorante è in ottime condizioni, ed anche molto pulito. Cibo genuino tipico degli agriturismo. Servizio impeccabile, le persone che ci hanno servito Sono state molto cortesi e cordiali. Tornerò sicuramente</p>

                <div class="client-info">
                    <h3>moribos</h3>
                    <span>Cliente</span>
                </div>
            </div>

            <div class="single-feedback">
                <p>Abbiamo casualmente trovato questo agriturismo cercando tra le varie offerte che la zona offre. E' immerso nel silenzio e nella natura, la struttura è nuova, e dai tavoli vicini alle ampie finestrate si gode un panorama stupendo. Ottime le portate, dagli antipasti ai cannoli...portate abbondanti...personale molto gentile e disponibile a venire incontro anche alle esigenze del nostro bambino. Ottimo anche il rapporto qualità/prezzo, sicuramente da consigliare a tutti. e sicuramente torneremo.</p>

                <div class="client-info">
                    <h3>FrancescoR638</h3>
                    <span>Cliente</span>
                </div>
            </div>
        </div>
    </div>
</section>

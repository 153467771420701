<app-header-one></app-header-one>

<div class="page-title-area page-title-bg1">
    <div class="container">
        <h3>Blog</h3>
    </div>

    <div id="particles-js"></div>
</div>

<section id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                        </ul>
                        <h3><a routerLink="/blog-details">The 10 Essential Los Angeles Restaurants, 2020</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                        </ul>
                        <h3><a routerLink="/blog-details">16 Hottest Restaurants in LA Right Now, 2020</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                        </ul>
                        <h3><a routerLink="/blog-details">Friday Fun Food Facts – March 6, 2020</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                        </ul>
                        <h3><a routerLink="/blog-details">Inside Hollywood’s Sprawling New Coffee</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                        </ul>
                        <h3><a routerLink="/blog-details">Brand New Eateries to Check Out in Los Angeles</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img6.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                        </ul>
                        <h3><a routerLink="/blog-details">High Level of Food Activity Continues</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-navbar">
                    <nav aria-label="navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item"><a class="page-link" href="#"><i class="icofont-double-left"></i></a></li>
                            <li class="page-item active"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#"><i class="icofont-double-right"></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<app-instagram></app-instagram>
<section id="contact" class="contact-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="contact-box">
                    <h3>Contattaci</h3>
                    <p><a href="https://goo.gl/maps/xEJcQc5buTns5EVT9" target="_blank"><i class="icofont-google-map"></i> Contrada Minavento, 92020, Santo Stefano Quisquina (AG)</a></p>
                    <p><a href="tel:+393383187624"><i class="icofont-phone"></i> +39 338 3187624</a></p>
                    <p><a href="mailto:buonanotteresort@gmail.com"><i class="icofont-envelope"></i> buonanotteresort@gmail.com</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-box">
                    <h3>Orario di Apertura</h3>
                    <p class="opening-hours">Pranzo  <span>12:30 - 14:00</span></p>
                    <p class="opening-hours">Cena    <span>19:30 - 23:00</span></p>
                    <p class="opening-hours">Martedì <span>Chiuso</span></p>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <form (ngSubmit)="onSubmit(emailForm)" id="contactForm" #emailForm="ngForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input name="name" type="text" class="form-control" placeholder="Nome" required [(ngModel)]="formData.name" >
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input name="lastname" type="text" class="form-control" placeholder="Cognome" required [(ngModel)]="formData.lastname" >
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input name="email" type="email" class="form-control" placeholder="Email" required [(ngModel)]="formData.email" >
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input name="telephone" type="text" class="form-control" placeholder="Telefono" required [(ngModel)]="formData.telephone" >
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea  name="message" cols="30" rows="5" class="form-control" placeholder="Messaggio..." required [(ngModel)]="formData.message" ></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary" [disabled]="!emailForm.valid">Invia Messaggio</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<section id="offer" class="offer-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
            <span>Offerte Sorprendenti</span>
            <h2>I nostri menù speciali</h2>
        </div>

        <div class="offer-slides owl-carousel owl-theme">
            <div class="single-offer">
                <a href="#"><img src="assets/img/fp/P1000233.jpg" alt="offer-img"></a>

                <div class="offer-content">
                    <h3><span>Menù Degustazione</span> €30,00 - Il sapore della nostra cucina (min. 2 persone)</h3>
                    <p>Antipasto del Borgo + 2 Primi + Tris di Carne + Contorno + Frutta e Dessert + Vino e Acqua</p>
                    <a href="https://www.thefork.it/ristorante/borgo-buonanotte-r495185" target="_blank" class="btn btn-primary">Prenota Ora</a>
                </div>
            </div>

            <div class="single-offer">
                <a href="#"><img src="assets/img/fp/P1000189.jpg" alt="offer-img"></a>

                <div class="offer-content">
                    <h3><span>Menù Giro Pizza</span> €20,00 - (min. 4 persone)</h3>
                    <p>Antipasti misti + Pizze illimitate tra le nostre classiche + Bibite (birra esclusa)</p>
                    <a href="https://www.thefork.it/ristorante/borgo-buonanotte-r495185" target="_blank" class="btn btn-primary">Prenota Ora</a>
                </div>
            </div>

            <div class="single-offer">
                <a href="#"><img src="assets/img/cotoletta.jpg" alt="offer-img"></a>

                <div class="offer-content">
                    <h3><span>Menù Bambini</span> €18,00 - La nostra proposta per i più piccoli</h3>
                    <p>Antipasto all'Italiana + Pasta al Pomodoro + Cotoletta Panata con Patatine</p>
                    <a href="https://www.thefork.it/ristorante/borgo-buonanotte-r495185" target="_blank" class="btn btn-primary">Prenota Ora</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="cta-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-9 col-md-12">
                <span>Ti aspettiamo!</span>
                <h2>Vieni a provare l'autentica cucina dei Monti Sicani</h2>
            </div>

            <div class="col-lg-3 col-md-12 text-right">
                <a href="#contact" class="btn btn-primary">Contattaci</a>
            </div>
        </div>
    </div>
</section>

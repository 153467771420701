<section id="menu" class="menu-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Esplora</span>
            <h2>Il nostro Menù</h2>
        </div>

        <div class="tab">
            <ul class="tabs">
                <li><a href="#">
                    <i class="icofont-french-fries"></i>
                    <br>
                    Antipasti
                </a></li>

                <li><a href="#">
                    <i class="icofont-lunch"></i>
                    <br>
                    Primi
                </a></li>

                <li><a href="#">
                    <i class="icofont-bbq"></i>
                    <br>
                    Secondi
                </a></li>

                <li><a href="#">
                    <i class="icofont-pizza"></i>
                    <br>
                    Pizza
                </a></li>

                <li><a href="#">
                    <i class="icofont-potato"></i>
                    <br>
                    Contorni
                </a></li>

                <li><a href="#">
                    <i class="icofont-soft-drinks"></i>
                    <br>
                    Bevande
                </a></li>
                <li><a href="#">
                    <i class="icofont-cup-cake"></i>
                    <br>
                    Dolci
                </a></li>
            </ul>

            <div class="tab_content">
                <!-- STARTERS -->
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/antipasti/fritto-misto.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Fritto Misto* </h3>
                                    <ul>
                                        <li>Grande<br><span class="menu-price">€8,50</span></li>
                                        <li>Medio<br><span class="menu-price">€6,00</span></li>
                                        <li>Piccolo<br><span class="menu-price">€4,50</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/antipasti/patatine.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Patatine Fritte* </h3>
                                    <ul>
                                        <li>Grande<br><span class="menu-price">€8,00</span></li>
                                        <li>Medio<br><span class="menu-price">€5,50</span></li>
                                        <li>Piccolo<br><span class="menu-price">€4,00</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/antipasti/antipasto-classico-allitaliana.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Antipasto all'Italiana <span class="menu-price">€9,00</span></h3>
                                    <ul>
                                        <li>Prosciutto Cotto</li>
                                        <li>Salame</li>
                                        <li>Formaggio</li>
                                        <li>Olive Verdi</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/antipasti/P1000234.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Antipasto del Borgo <span class="menu-price">€13,00</span></h3>
                                    <ul>
                                        <li>Selezione di Prodotti a km 0</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="../../../../assets/img/antipasti/caprese-mozzarella-pomodori.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Caprese <span class="menu-price">€6,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella Fior di Latte locale</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/antipasti/tagliere-di-formaggi-11.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Tagliere di Formaggi <span class="menu-price">€9,00</span></h3>
                                    <ul>
                                        <li>Formaggi locali</li>
                                        <li>Confettura</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/antipasti/tagliere-misto-solo-salumi.jpeg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Tagliere di Salumi <span class="menu-price">€9,00</span></h3>
                                    <ul>
                                        <li>Salumi locali</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- MAIN DISHES -->
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img1.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Penne all'Arrabbiata <span class="menu-price">€8,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Aglio</li>
                                        <li>Olio</li>
                                        <li>Peperoncino</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img1.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Caserecce alla Boscaiola <span class="menu-price">€10,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Pancetta</li>
                                        <li>Salsiccia</li>
                                        <li>Funghi</li>
                                        <li>Panna</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img1.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Spaghetti alla Puttanesca <span class="menu-price">€8,00</span></h3>
                                    <ul>
                                        <li>Aglio</li>
                                        <li>Capperi</li>
                                        <li>Olive</li>
                                        <li>Acciughe</li>
                                        <li>Pomodoro</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img1.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Caserecce Santa Rosalia <span class="menu-price">€8,50</span></h3>
                                    <ul>
                                        <li>Pesto di Basilico</li>
                                        <li>Pancetta Fresca</li>
                                        <li>Ricotta Fresca</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img1.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Penne alla Norma <span class="menu-price">€8,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Melanzane</li>
                                        <li>Scaglie di Ricotta Salata</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img1.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Caserecce del Borgo <span class="menu-price">€10,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Melanzane</li>
                                        <li>Funghi</li>
                                        <li>Speck</li>
                                        <li>Crudo</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img1.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Spaghetti alla Carbonara <span class="menu-price">€9,00</span></h3>
                                    <ul>
                                        <li>Guanciale</li>
                                        <li>Tuorlo d'Uovo</li>
                                        <li>Parmigiano</li>
                                        <li>Pepe</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img1.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ravioli di Ricotta e Spinaci<span class="menu-price">€12,00</span></h3>
                                    <ul>
                                        <li>Crema di Girgonzola e Noci</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img1.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ciuffetti <span class="menu-price">€12,00</span></h3>
                                    <ul>
                                        <li>Fontina</li>
                                        <li>Speck</li>
                                        <li>Crema al Pistacchio</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img1.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ravioli ai Porcini <span class="menu-price">€12,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Pancetta</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- SECOND DISHES -->
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/bistecca.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Trippa al Pomodoro e Fagioli <span class="menu-price">€7,50</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/bistecca.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Bollito di Pecora <span class="menu-price">€8,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/bistecca.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Grigliata Mista <span class="menu-price">€15,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/bistecca.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Scaloppina al Limone <span class="menu-price">€13,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/bistecca.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Scaloppina ai Funghi <span class="menu-price">€13,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/bistecca.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Tagliata di Manzo con Rucola e Grana <span class="menu-price">€19,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/bistecca.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Tagliata di Manzo con Salsa alle Noci <span class="menu-price">€20,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/bistecca.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Bistecca ai Ferri <span class="menu-price">€14,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/bistecca.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Agnello a scottadito <span class="menu-price">€14,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/bistecca.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Fiorentina/Costata <span class="menu-price">Vedi Vetrina</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- PIZZAs -->
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ai Funghi <span class="menu-price">€7,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Funghi</li>
                                        <li>Rucola</li>
                                        <li>Scaglie di Grana</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ai Wurstel <span class="menu-price">€6,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Wurstel</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ai Carciofi <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Prosciutto Cotto</li>
                                        <li>Carciofi</li>
                                        <li>Olive</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ai Funghi Tartufati <span class="menu-price">€7,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Speck</li>
                                        <li>Funghi</li>
                                        <li>Tartufati</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Americana <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Wurstel</li>
                                        <li>Patatine Fritte</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Al Tonno <span class="menu-price">€6,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Tonno</li>
                                        <li>Cipolla</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Alla Norma <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Pomodorini</li>
                                        <li>Melanzane</li>
                                        <li>Scaglie di Ricotta</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Braccio di Ferro <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Spinaci</li>
                                        <li>Gorgonzola</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Bufala <span class="menu-price">€9,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella di Bufala</li>
                                        <li>Bresaola</li>
                                        <li>Scaglie di Grana</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/capricciosa.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Capricciosa <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Prosciutto Cotto</li>
                                        <li>Funghi</li>
                                        <li>Wurstel</li>
                                        <li>Uovo Sodo</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/caprese.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Caprese <span class="menu-price">€8,50</span></h3>
                                    <ul>
                                        <li>Mozzarella di bufala a Fette</li>
                                        <li>Pomodoro a Fette</li>
                                        <li>Prosciutto Crudo</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Calzone <span class="menu-price">€6,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Prosciutto Cotto</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Calabrese <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Salame Piccante</li>
                                        <li>Pomodori Secchi</li>
                                        <li>Acciughe</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/campana.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Campana <span class="menu-price">€8,50</span></h3>
                                    <ul>
                                        <li>Mozzarella di Bufala</li>
                                        <li>Pomodorini</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/delicata.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Delicata <span class="menu-price">€8,00</span></h3>
                                    <ul>
                                        <li>Mozzarella</li>
                                        <li>Zucchine</li>
                                        <li>Pomodorini</li>
                                        <li>Philadelphia</li>
                                        <li>Speck</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Deliziosa <span class="menu-price">€7,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Pomodorini</li>
                                        <li>Prosciutto Crudo</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/del-borgo.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Del Borgo <span class="menu-price">€8,50</span></h3>
                                    <ul>
                                        <li>Mozzarella</li>
                                        <li>Pomodorini</li>
                                        <li>Funghi</li>
                                        <li>Salsiccia</li>
                                        <li>Rucola</li>
                                        <li>Scaglie di Grana</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/diavola.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Diavola <span class="menu-price">€7,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Salame Piccante</li>
                                        <li>Salsiccia</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Funghissima <span class="menu-price">€8,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Funghi Freschi</li>
                                        <li>Funghi Tartufati</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Focaccia <span class="menu-price">€5,00</span></h3>
                                    <ul>
                                        <li>Formaggio</li>
                                        <li>Cipolla</li>
                                        <li>Acciughe</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Genovese <span class="menu-price">€7,50</span></h3>
                                    <ul>
                                        <li>Mozzarella</li>
                                        <li>Pomodoro a fette</li>
                                        <li>Pesto di Basilico</li>
                                        <li>Prosciutto Crudo</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Golosa <span class="menu-price">€8,50</span></h3>
                                    <ul>
                                        <li>Mozzarella</li>
                                        <li>Carciofi</li>
                                        <li>Pomodori Secchi</li>
                                        <li>Cipolla</li>
                                        <li>Olive Nere</li>
                                        <li>Salsiccia</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Marinara <span class="menu-price">€5,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Aglio</li>
                                        <li>Olio</li>
                                        <li>Origano</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/margherita.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Margherita <span class="menu-price">€5,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Napoli <span class="menu-price">€6,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Acciughe</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ortolana <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Melanzane</li>
                                        <li>Zucchine</li>
                                        <li>Spinaci</li>
                                        <li>Carciofi</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/parmigiana.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Parmigiana <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Melanzane</li>
                                        <li>Parmigiano</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Quattro Gusti <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Prosciutto Cotto</li>
                                        <li>Funghi</li>
                                        <li>Olive</li>
                                        <li>Carciofi</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Quattro Formaggi <span class="menu-price">€6,50</span></h3>
                                    <ul>
                                        <li>Mozzarella</li>
                                        <li>Emmental</li>
                                        <li>Gorgonzola</li>
                                        <li>Parmigiano</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Romana <span class="menu-price">€6,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Prosciutto Cotto</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Rustica <span class="menu-price">€6,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Formaggio Primo Sale</li>
                                        <li>Cipolla</li>
                                        <li>Salsiccia</li>
                                        <li>Olive</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/rucola.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Rucola <span class="menu-price">€8,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Crudo</li>
                                        <li>Rucola</li>
                                        <li>Scaglie di Grana</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Reale <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Salsiccia</li>
                                        <li>Carciofi</li>
                                        <li>Pancetta</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>San Daniele <span class="menu-price">€7,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Prosciutto Crudo</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Saporita <span class="menu-price">€7,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Prosciutto Cotto</li>
                                        <li>Salame Dolce</li>
                                        <li>Wurstel</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/sorriso.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Sorriso <span class="menu-price">€7,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Tonno</li>
                                        <li>Pomodoro Bruschetta</li>
                                        <li>Scaglie di Grana</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Sfiziosa <span class="menu-price">€8,00</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Salame Piccante</li>
                                        <li>Salsiccia</li>
                                        <li>Scamorza Affumicata</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>S. Rosalia <span class="menu-price">€9,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Pomodorini</li>
                                        <li>Rucola</li>
                                        <li>Bresaola</li>
                                        <li>Scaglie di Grana</li>
                                        <li>Glassa di Aceto Balsamico</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Siciliana <span class="menu-price">€5,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Formaggio</li>
                                        <li>Cipolla</li>
                                        <li>Acciughe</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Tirolese <span class="menu-price">€7,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Gorgonzola</li>
                                        <li>Funghi</li>
                                        <li>Speck</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Valtellina <span class="menu-price">€7,50</span></h3>
                                    <ul>
                                        <li>Mozzarella</li>
                                        <li>Philadelphia</li>
                                        <li>Funghi</li>
                                        <li>Speck</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Pizza Nutella <span class="menu-price">€5,00</span></h3>
                                    <ul>
                                        <li>Nutella</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Pizza Pistacchio <span class="menu-price">€7,50</span></h3>
                                    <ul>
                                        <li>Crema di Pistacchio</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/emiliana.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Emiliana <span class="menu-price">€10,00</span></h3>
                                    <ul>
                                        <li>Mozzarella Fresca</li>
                                        <li>Mortadella</li>
                                        <li>Pesto e Granella di Pistacchio</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/luana.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Luana <span class="menu-price">€10,00</span></h3>
                                    <ul>
                                        <li>Mozzarella</li>
                                        <li>Philadelphia</li>
                                        <li>Speck</li>
                                        <li>Pesto e Granella di Pistacchio</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/valeria.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Valeria <span class="menu-price">€11,50</span></h3>
                                    <ul>
                                        <li>Mozzarella di Bufala</li>
                                        <li>Salame Piccante</li>
                                        <li>Salsiccia</li>
                                        <li>Funghi</li>
                                        <li>Pesto e Granella di Pistacchio</li>
                                        <li>Cipolla</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/luana-2.0.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Luana 2.0 <span class="menu-price">€11,50</span></h3>
                                    <ul>
                                        <li>Mozzarella</li>
                                        <li>Stracciatella</li>
                                        <li>Speck</li>
                                        <li>Pesto e Granella di Pistacchio</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/italia.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Italia <span class="menu-price">€11,50</span></h3>
                                    <ul>
                                        <li>Pomodoro</li>
                                        <li>Mozzarella</li>
                                        <li>Crudo</li>
                                        <li>Rucola</li>
                                        <li>Pomodorini</li>
                                        <li>Stracciatella</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Caprese 2.0 <span class="menu-price">€12,00</span></h3>
                                    <ul>
                                        <li>Base Bianca</li>
                                        <li>Olio</li>
                                        <li>Origano</li>
                                        <li>Fior di Latte a fette</li>
                                        <li>Rucola</li>
                                        <li>Speck</li>
                                        <li>Datterino Giallo Semisecco</li>
                                        <li>Burrata</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img4.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Giada <span class="menu-price">€12,00</span></h3>
                                    <ul>
                                        <li>Mozzarella</li>
                                        <li>Philadelphia</li>
                                        <li>Speck</li>
                                        <li>Pesto e Granella di Pistacchio</li>
                                        <li>Rucola</li>
                                        <li>Pomodorini gialli e rossi Semisecchi</li>
                                        <li>Bufala a crudo</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/pizze/pugliese.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Pugliese <span class="menu-price">€12,00</span></h3>
                                    <ul>
                                        <li>Fior di Latte</li>
                                        <li>Capocollo</li>
                                        <li>Rucola</li>
                                        <li>Pomodorini Semisecchi</li>
                                        <li>Burrata</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- SIDES -->
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img2.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Insalata Verde <span class="menu-price">€4,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img2.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Insalata Mista <span class="menu-price">€4,50</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img2.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Funghi Trifolati <span class="menu-price">€5,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img2.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Patatine Fritte* <span class="menu-price">€4,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/menu-img2.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Spinaci al Burro <span class="menu-price">€5,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- BEVERAGE -->
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/vino.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Acqua Minerale - Frizzante <span class="menu-price">€2,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/vino.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Coca Cola (1L) <span class="menu-price">€3,50</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/vino.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Fanta (1L) <span class="menu-price">€3,50</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/vino.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Lattine <span class="menu-price">€2,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/vino.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Heineken </h3>
                                    <ul>
                                        <li><h3>66cl<br><span class="menu-price">€3,50</span></h3></li>
                                        <li><h3>33cl<br><span class="menu-price">€2,50</span></h3></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/vino.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Semedorato </h3>
                                    <ul>
                                        <li><h3>66cl<br><span class="menu-price">€3,50</span></h3></li>
                                        <li><h3>33cl<br><span class="menu-price">€2,50</span></h3></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/vino.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ichnusa 50cl<span class="menu-price">€3,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/vino.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Messina Cristalli di Sale 50cl<span class="menu-price">€3,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/vino.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Vino della Casa (bianco o rosso) </h3>
                                    <ul>
                                        <li><h3>25cl<br><span class="menu-price">€4,00</span></h3></li>
                                        <li><h3>50cl<br><span class="menu-price">€6,00</span></h3></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- SWEETS -->
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/dolce.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Panna Cotta <span class="menu-price">€4,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/dolce.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Cannolicchi di Ricotta <span class="menu-price">€0,80 cad</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/dolce.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ravioli di Ricotta <span class="menu-price">€0,80 cad</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/dolce.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Ravioli di Ricotta e Pistacchio <span class="menu-price">€1,00 cad</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/dolce.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Cestini di Frolla alla Nocciola <span class="menu-price">€0,80 cad</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/dolce.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Cestini di Frolla al Pistacchio <span class="menu-price">€1,00 cad</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/dolce.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Cuore Caldo al Ciocciolato <span class="menu-price">€4,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/dolce.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Semifreddo Cannolo <span class="menu-price">€4,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/dolce.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Semifreddo al Pistacchio (no glutine) <span class="menu-price">€4,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="single-menu">
                                <div class="food-menu-img">
                                    <img src="assets/img/dolce.jpg" alt="food-img">
                                </div>

                                <div class="food-menu-content">
                                    <h3>Semifreddo alle Mandorle (no glutine) <span class="menu-price">€4,00</span></h3>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="line-bg"><img src="assets/img/line.png" alt="line"></div>
</section>

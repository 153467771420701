<app-header-one></app-header-one>

<div class="page-title-area page-title-bgferragosto">
    <div class="container">
        <h3>Menu di Ferragosto 2023</h3>
    </div>

    <div id="particles-js"></div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 offset-lg-2">
                <div class="blog-details">
                    <div class="article-img">
                        <img src="assets/img/offers/ferragosto.jpg" alt="ferragosto">
                        <div class="date">15 <br> Agosto</div>
                    </div>
                    <div class="article-content">
                        <ul class="category">
                            <li><a href="#">Restaurants</a></li>
                            <li><a href="#">Angeles</a></li>
                            <li><a href="#">Food</a></li>
                        </ul>
                        <h3>Antipasti</h3>
                        <blockquote class="blockquote">
                            <ul class="list-group">
                                <li>Triangolini di formaggio</li>
                                <li>Selezione di salami locali</li>
                                <li>Carpaccio di arista al pomodoro secco</li>
                                <li>Cupole di ricotta con miele e granella</li>
                                <li>Torta salata</li>
                                <li>Caponatina di ortaggi in agrodolce</li>
                                <li>Trippa con fagioli</li>
                                <li>Brasato di pecora</li>
                            </ul>
                        </blockquote>

                        <h3>Primi</h3>
                        <blockquote class="blockquote">
                            <ul class="list-group">
                                <li>Panzerotti di melanzane e scamorza al pomodoro e basilico</li>
                                <li>Busiate con crema di tenerumi, stracciatella e mandorla</li>
                            </ul>
                        </blockquote>
                        <h3>Secondi</h3>
                        <blockquote class="blockquote">
                            <ul class="list-group">
                                <li>Agnello al forno</li>
                                <li>Salsiccia alla griglia</li>
                                <li>Porchetta locale</li>
                                <li>Insalata mista</li>
                            </ul>
                        </blockquote>

                        <h3>Frutta e dolce</h3>
                        <blockquote class="blockquote">
                            <ul class="list-group">
                                <li>Acqua naturale e frizzante</li>
                                <li>Vino locale</li>
                                <li>Caffè e digestivo</li>
                            </ul>
                        </blockquote>

                        <h2>Prezzo €35,00</h2>
                        <p><a href="tel:+393383187624" class="btn btn-primary">Chiama per prenotare!</a></p>


                        <div class="share-post">
                            <ul>
                                <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Facebook"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Twitter"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Linkedin"><i class="icofont-linkedin"></i></a></li>
                                <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Instagram"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Vimeo"><i class="icofont-vimeo"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-instagram></app-instagram>

<section class="features-area ptb-100 pb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-soft-drinks"></i>
                    </div>
                    <div class="features-content">
                        <h3>Hot Spirits</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>

                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-box"></i>
                    </div>
                    <div class="features-content">
                        <h3>Packaged Foods</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>

                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-chicken-fry"></i>
                    </div>
                    <div class="features-content">
                        <h3>Spicy Stuff</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-img">
                    <img src="assets/img/features-img1.jpg" alt="features-img">
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-food-basket"></i>
                    </div>
                    <div class="features-content">
                        <h3>Salubrious Snacks</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>

                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-cocktail"></i>
                    </div>
                    <div class="features-content">
                        <h3>Healthy Drinks</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>

                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-cup-cake"></i>
                    </div>
                    <div class="features-content">
                        <h3>Chocolate Desserts</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-9">
                <p>Copyright <i class="icofont-copyright"></i>2023 Buonanotte Resort S.r.l. P.I. 02695620845 - All rights reserved - Powered by <i><a href="https://it.linkedin.com/in/darioparrino" target="_blank">Dario Parrino</a></i></p>
            </div>

            <div class="col-lg-3 col-md-3">
                <ul>
                    <li><a href="https://www.iubenda.com/privacy-policy/62384684" target="_blank">Privacy Policy</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>

<app-reservation-modal></app-reservation-modal>

<section id="chefs" class="chefs-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Staff Esperto</span>
            <h2>Conosci il nostro personale</h2>
        </div>

        <div class="chefs-slides owl-carousel owl-theme">
            <div class="single-chefs">
                <div class="chefs-image">
                    <img src="assets/img/fp/chef.png" alt="chef-img">

                    <ul class="chefs-social">
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="chefs-content">
                    <h3>Claudio</h3>
                    <span>Chef</span>
                </div>
            </div>

            <div class="single-chefs">
                <div class="chefs-image">
                    <img src="assets/img/fp/caposala.png" alt="chef-img">

                    <ul class="chefs-social">
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="chefs-content">
                    <h3>Luana</h3>
                    <span>Capo Sala</span>
                </div>
            </div>

            <div class="single-chefs">
                <div class="chefs-image">
                    <img src="assets/img/fp/pizzaiolo.png" alt="chef-img">

                    <ul class="chefs-social">
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="chefs-content">
                    <h3>Giuseppe</h3>
                    <span>Pizzaiolo</span>
                </div>
            </div>
        </div>
    </div>

    <div class="line-bg"><img src="assets/img/line.png" alt="line"></div>
</section>

<section id="about" class="story-area ptb-100">
    <div class="section-title">
        <span>Il Ristorante</span>
        <h2>Un Tuffo nella Tradizione</h2>
    </div>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="story-image">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <img src="assets/img/fp/P1000154.jpg" alt="image">
                            <img src="assets/img/fp/P1000259.jpg" alt="image">
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <img src="assets/img/about-img3.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="story-content">
                    <div class="section-title">
                        <span>La cucina</span>
                    </div>
                    <p>
                        Borgo Buonanotte è il sapore della cucina tradizionale, il gusto del sentirsi accolti, a casa. Il ristorante propone piatti tipici dei Monti Sicani preparati con cura, partendo da ingredienti e materie prime di qualità.
                    </p>
                    <p>
                        La cucina è mediterranea, con gustose ricette di terra con proposte per tutti i gusti. Il ristorante offre quindi specialità di carne, buonissimi antipasti di salumi e formaggi del territorio.
                    </p>
                </div>
                <div class="story-content">
                    <div class="section-title">
                        <span>La pizzeria</span>
                    </div>
                    <p>
                        Borgo Buonanotte è anche una pizzeria, un contesto accogliente e famigliare dove poter apprezzare una buona pizza. La pizzeria offre pizze fragranti e gustose, cotte nel forno a legna, preparate con ingredienti selezionati, freschi e genuini.
                    </p>
                    <p>
                        La proposta della pizzeria prevede un menù che comprende pizze tradizionali e pizze speciali. Sono fatte con un impasto leggero, digeribile, a base di farina di grani antichi . Una pizzeria ospitale, un’ottima pizza: vi aspettiamo!
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div id="particles-js-circle-bubble-1"></div>
    <div class="line-bg"><img src="assets/img/line.png" alt="line"></div>
</section>

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  apiEndpoint = '../assets/php/mail.php';
  constructor(private http: HttpClient) { }

    sendEmail(param: {name: string; lastname: string, telephone: string, email: string; message: string}) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            })
        };

        const body = {
            name: param.name,
            lastname: param.lastname,
            email: param.email,
            message: param.message
        };

        this.http.post(this.apiEndpoint, body, httpOptions).subscribe(
            (response) => {
                console.log(response);
            },
            (error) => {
                console.log(error);
            }
        );
    }
}

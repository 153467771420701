<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <h3>Single Blog</h3>
    </div>

    <div id="particles-js"></div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 offset-lg-2">
                <div class="blog-details">
                    <div class="article-img">
                        <img src="assets/img/blog-img1.jpg" alt="blog-details">
                        <div class="date">20 <br> Jan</div>
                    </div>
                    
                    <div class="article-content">
                        <ul class="category">
                            <li><a href="#">Restaurants</a></li>
                            <li><a href="#">Angeles</a></li>
                            <li><a href="#">Food</a></li>
                        </ul>
                            
                        <h3>The 10 Essential Los Angeles Restaurants, 2020</h3>
                        
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. In necessitatibus provident facere, reiciendis voluptate dolorem iste consectetur veniam aperiam suscipit ad ipsum et labore a repellendus debitis explicabo quisquam obcaecati....</p>
                        
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque eum alias debitis suscipit, sint dignissimos minus quisquam recusandae nostrum quas eligendi odit, fugiat eius rem. Cumque, labore placeat! Velit, vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus a labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate!</p>
                        
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus a labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate!</p>
                        
                        <blockquote class="blockquote">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus a labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate.</p>
                        </blockquote>
                        
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque eum alias debitis suscipit, sint dignissimos minus quisquam recusandae nostrum quas eligendi odit, fugiat eius rem. Cumque, labore placeat! Velit, vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus a labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate!</p>
                        
                        <div class="share-post">
                            <ul>
                                <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Facebook"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Twitter"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Linkedin"><i class="icofont-linkedin"></i></a></li>
                                <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Instagram"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Vimeo"><i class="icofont-vimeo"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div class="post-controls-buttons">
                    <div class="controls-left">
                        <a href="#"><i class="icofont-double-left"></i> Prev Post</a>
                    </div>

                    <div class="controls-right">
                        <a href="#">Next Post <i class="icofont-double-right"></i></a>
                    </div>
                </div>
                
                <div class="post-comments">
                    <h3>Comments</h3>
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/user1.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>John Smith</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <a href="#">Reply</a>
                        </div>
                    </div>
                    
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            <img src="assets/img/user2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Doe John</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <a href="#">Reply</a>
                        </div>
                    </div>
                    
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/user3.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Steven Doe</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <a href="#">Reply</a>
                        </div>
                    </div>
                    
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/user4.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>John Cina</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <a href="#">Reply</a>
                        </div>
                    </div>
                </div>
                
                <div class="leave-a-reply">
                    <h3>Leave a Reply</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" placeholder="Full Name">
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" class="form-control" placeholder="E-Mail">
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Your Comment"></textarea>
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-instagram></app-instagram>
<section id="gallery" class="gallery-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <span>Gallery</span>
            <h2>Dai un'occhiata alla nostra Gallery</h2>
        </div>
    </div>

    <div class="row m-0">
        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/fp/P1000153.jpg" alt="gallery-img">
                <a href="assets/img/fp/P1000153.jpg" class="popup-btn"><i class="icofont-search-restaurant"></i></a>

                <div class="image-content">
                    <h3><a href="#">Sala</a></h3>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/fp/P1000208.jpg" alt="gallery-img">
                <a href="assets/img/fp/P1000208.jpg" class="popup-btn"><i class="icofont-search-restaurant"></i></a>

                <div class="image-content">
                    <h3><a href="#">Bistecche Frollate</a></h3>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/fp/P1000157.jpg" alt="gallery-img">
                <a href="assets/img/fp/P1000157.jpg" class="popup-btn"><i class="icofont-search-restaurant"></i></a>

                <div class="image-content">
                    <h3><a href="#">Vini</a></h3>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/fp/P1000169.jpg" alt="gallery-img">
                <a href="assets/img/fp/P1000169.jpg" class="popup-btn"><i class="icofont-search-restaurant"></i></a>

                <div class="image-content">
                    <h3><a href="#">Forno a legna</a></h3>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/fp/P1000195.jpg" alt="gallery-img">
                <a href="assets/img/fp/P1000195.jpg" class="popup-btn"><i class="icofont-search-restaurant"></i></a>

                <div class="image-content">
                    <h3><a href="#">Pizzeria</a></h3>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/fp/P1000219.jpg" alt="gallery-img">
                <a href="assets/img/fp/P1000219.jpg" class="popup-btn"><i class="icofont-search-restaurant"></i></a>

                <div class="image-content">
                    <h3><a href="#">Trippa</a></h3>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/fp/P1000227.jpg" alt="gallery-img">
                <a href="assets/img/fp/P1000227.jpg" class="popup-btn"><i class="icofont-search-restaurant"></i></a>

                <div class="image-content">
                    <h3><a href="#">Antipasti Tipici</a></h3>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/fp/P1000156.jpg" alt="gallery-img">
                <a href="assets/img/fp/P1000156.jpg" class="popup-btn"><i class="icofont-search-restaurant"></i></a>

                <div class="image-content">
                    <h3><a href="#">Eventi</a></h3>
                </div>
            </div>
        </div>
    </div>
</section>

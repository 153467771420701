<header class="top-area">
    <div class="top-bar">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="call-to-action">
                        <p><i class="icofont-envelope"></i> Email: <a href="mailto:buonanotteresort@gmail.com">buonanotteresort@gmail.com</a></p>
                        <p><i class="icofont-phone"></i> Telefono: <a href="tel:+393383187624">+39 338 3187624</a></p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="top-social">
                        <li><a href="https://www.facebook.com/BorgoBuonanotte" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="https://instagram.com/parrinobuonanotte?igshid=YmMyMTA2M2Y=" target="_blank"><i class="icofont-instagram"></i></a></li>
                    </ul>
                    <div class="opening-hours">
                        <p><i class="icofont-wall-clock"></i> Orari di apertura: 12:30 - 14:00; 19:30 - 23:00 Chiusura: Martedì</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <nav class="navbar navbar-expand-lg navbar-light bg-light transparent-navbar">
        <div class="container">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo-green.png" alt="logo"></a>
            <a class="navbar-brand black-logo" routerLink="/"><img src="assets/img/logo-black.png" alt="logo-black"></a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item"><a class="nav-link" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#about">Ristorante</a></li>
                    <li class="nav-item"><a class="nav-link" href="#offer">Offerte</a></li>
                    <li class="nav-item"><a class="nav-link" href="#menu">Menu</a></li>
                    <li class="nav-item"><a class="nav-link" href="#chefs">Staff</a></li>
                    <li class="nav-item"><a class="nav-link" href="#gallery">Gallery</a></li>
                    <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>
                    <li class="nav-item"><a class="nav-link" href="#contact">Contatti</a></li>
                </ul>
            </div>
        </div>
    </nav>
</header>

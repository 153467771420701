import {Component} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

    constructor(
      private http: HttpClient
    ) { }

    formData = {
        name: '',
        lastname: '',
        email: '',
        telephone: '',
        message: ''
    };
    onSubmit(form: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this.http.post('/assets/php/mail.php', JSON.stringify(this.formData), httpOptions)
            .subscribe(
                response =>  {
                    console.log('Email inviata con successo! ', response);
                    alert('Email inviata con successo!');
                },
                error => {
                    console.error('Invio email fallito! ', error);
                    alert('Invio email fallito!');
                }
            );
    }
}

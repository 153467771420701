<section class="instagram-area">
    <div class="instagram-title">
        <h3><a href="https://instagram.com/parrinobuonanotte?igshid=YmMyMTA2M2Y=" target="_blank"><i class="icofont-instagram"></i> Seguici su @borgo-buonanotte</a></h3>
    </div>
    <div class="instagram-slides owl-carousel owl-theme">
        <div class="instagram-item">
            <a href="https://instagram.com/parrinobuonanotte?igshid=YmMyMTA2M2Y=" target="_blank"><img src="assets/img/fp/P1000153.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="https://instagram.com/parrinobuonanotte?igshid=YmMyMTA2M2Y=" target="_blank"><img src="assets/img/fp/P1000208.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="https://instagram.com/parrinobuonanotte?igshid=YmMyMTA2M2Y=" target="_blank"><img src="assets/img/fp/P1000157.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="https://instagram.com/parrinobuonanotte?igshid=YmMyMTA2M2Y=" target="_blank"><img src="assets/img/fp/P1000169.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="https://instagram.com/parrinobuonanotte?igshid=YmMyMTA2M2Y=" target="_blank"><img src="assets/img/fp/P1000195.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="https://instagram.com/parrinobuonanotte?igshid=YmMyMTA2M2Y=" target="_blank"><img src="assets/img/fp/P1000227.jpg" alt="instagram-image"></a>
        </div>

        <div class="instagram-item">
            <a href="https://instagram.com/parrinobuonanotte?igshid=YmMyMTA2M2Y=" target="_blank"><img src="assets/img/fp/P1000156.jpg" alt="instagram-image"></a>
        </div>
    </div>
</section>

<section class="video-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="video-content">
            <h2>Immergiti nel cuore dei Monti Sicani</h2>
            <a href="https://www.youtube.com/watch?v=bNInvO2qgXE" class="video-btn popup-youtube"><i class="icofont-play"></i></a>
        </div>
        <div class="video-content">
            <h2>Seconda Parte</h2>
            <a href="https://www.youtube.com/watch?v=T5P-_GrHftA" class="video-btn popup-youtube"><i class="icofont-play"></i></a>
        </div>
    </div>
</section>

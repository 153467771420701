<section class="stunning-things ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Per il Vostro Comfort</span>
            <h2>Cosa trovi da noi</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-fast-delivery"></i>
                    </div>
                    <h3>Consegna a Domicilio</h3>
                    <p>Non puoi venire a trovarci? Nessun problema! Consegnamo le tue ordinazioni direttamente a casa tua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-restaurant"></i>
                    </div>
                    <h3>Alimenti di alta qualità</h3>
                    <p>La dedizione e la cura per i nostri clienti ci porta a selezionare per voi una vasta gamma di prodotti sani e di altissima qualità.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-cocktail"></i>
                    </div>
                    <h3>Ricette della Tradizione</h3>
                    <p>Da noi puoi assaporare i piatti tipici della tradizione vera dei Monti Sicani nella nostra splendida location immersa nella natura.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-chicken"></i>
                    </div>
                    <h3>Pasti Salutari</h3>
                    <p>I nostri piatti sono 100% naturali, senza conservanti e con pochissimi grassi saturi, ideali per chi vuol mangiare bene e con gusto.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-chef"></i>
                    </div>
                    <h3>Staff Esperto</h3>
                    <p>Il nostro staff è selezionato e formato per lavorare come parte di una squadra per garantire ai nostri clienti la migliore esperienza possibile.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-chicken-fry"></i>
                    </div>
                    <h3>Ingredienti Genuini</h3>
                    <p>Gli ingredienti e le materie prime utilizzate sono selezionati in base a criteri di assoluta genuinità e freschezza.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="line-bg"><img src="assets/img/line.png" alt="line"></div>
</section>

<app-header-one></app-header-one>

<div id="home" class="main-banner item-bg-one jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1>Borgo Buonanotte</h1>
                    <h3>Sito in Costruzione</h3>
                    <a href="https://www.thefork.it/ristorante/borgo-buonanotte-r495185" target="_blank" class="btn btn-primary" data-toggle="modal" data-target="#reservationModal">Prenota Ora</a>
                </div>
            </div>
        </div>
    </div>

    <div class="down_arrow">
        <a href="#about" class="scroll_down"></a>
    </div>
</div>

<app-about></app-about>

<app-offer></app-offer>

<app-stunning-things></app-stunning-things>

<app-video></app-video>

<app-menu></app-menu>

<app-book-table></app-book-table>

<app-chefs></app-chefs>

<app-cta></app-cta>

<app-gallery></app-gallery>

<app-feedback></app-feedback>

<section id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>From Our Blog</span>
            <h2>Latest News</h2>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The 10 Essential Los Angeles Restaurants, 2020</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                    </ul>
                    <h3><a routerLink="/blog-details">16 Hottest Restaurants in LA Right Now, 2020</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                    </ul>
                    <h3><a routerLink="/blog-details">Friday Fun Food Facts – March 6, 2020</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                    </ul>
                    <h3><a routerLink="/blog-details">Inside Hollywood’s Sprawling New Coffee</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
                    </ul>
                    <h3><a routerLink="/blog-details">Brand New Eateries to Check Out in Los Angeles</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <app-partner></app-partner> -->

<app-contact></app-contact>

<app-instagram></app-instagram>
